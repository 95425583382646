import React from 'react'

export const Spinner = () => (
  <div
    className="spinner-border spinner-border-sm spinner-grow-sm"
    role="status"
  >
    {/* <span className="sr-only">Loading...</span> */}
  </div>
)
