import React from 'react'
import { FallbackProps } from 'react-error-boundary'

export function ErrorFallback({ error }: FallbackProps) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      {error ? (
        <>
          <pre>{error.message}</pre>
          <pre>{error.stack}</pre>
        </>
      ) : null}
    </div>
  )
}
