/**
 * environment based on hostname
 *
 * hostname instead of env var so that the same builds can be used on staging and prod,
 * end doesn't require a dynamic backend
 */
enum Environment {
  development = 'DEVELOPMENT',
  staging = 'STAGING',
  production = 'PRODUCTION',
}

function getEnv(): Environment {
  const currentHost = new URL(window.location.href).hostname

  if (
    currentHost.endsWith('.herokuapp.com') &&
    currentHost !== 'myleapfund.herokuapp.com'
  )
    return Environment.staging

  if (currentHost === 'localhost' || currentHost === '127.0.0.1')
    return Environment.development

  return Environment.production
}

const environment = getEnv()

export { environment, Environment }
