import ahoy from 'ahoy.js'
import { GuidebookLink } from './GuidebookLink'

type LinkType = {
  url: string
  text: string
}

export const Disclaimer = (props: { links?: Array<LinkType> }) => (
  <div className="disclaimer-section">
    <div className="disclaimer">
      <span>
        Leap Fund calculator results are for informational purposes only and
        should not be considered financial advice. Coaches and clients are
        encouraged to review all available options and make decisions about
        benefits and finances that align with your client’s individual goals.{' '}
        {props.links ? (
          <>
            For more information on benefits eligibility, please visit{' '}
            <Links links={props.links} />.
          </>
        ) : null}
      </span>
    </div>

    <div className="disclaimer">
      <div>
        * Tax predictions divide yearly federal taxes into estimated monthly
        amounts.{' '}
        <GuidebookLink
          event="Results Page - Guidebook page for taxes"
          href="/guidebook/Tax%20Features%20e3fbbce5df3744e4b24b042aa498c105.html"
        />
      </div>
      <div>
        ** Auxiliary benefits are included in the predicted amount, but not in
        the self reported SSDI{' '}
        <GuidebookLink
          event="Results Page - Guidebook page for SSDI"
          href="/guidebook/SSDI%20Features%201189bfe47c3f80cab29aeecde9f9360b.html"
        />
      </div>
    </div>
  </div>
)

const Links = ({ links }: { links: Array<LinkType> }) => {
  switch (links.length) {
    case 1:
      return <Link link={links[0]} />
    case 2:
      return (
        <>
          <Link link={links[0]} /> and <Link link={links[1]} />
        </>
      )
    default:
      throw new Error(`unexpected number of links: ${links.length}`)
  }
}

const Link = ({ link }: { link: LinkType }) => (
  <a
    href={link.url}
    target="_blank"
    rel="noopener noreferrer"
    onClick={() =>
      ahoy.track('Results Page - Benefits Eligibility for Their Area', {
        language: 'JavaScript',
      })
    }
  >
    {link.text}
  </a>
)
