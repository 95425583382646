import { BKApi } from '../javascript/types/BenefitKitchen'
import { z } from 'zod'

export const leapfundStateSchema = z.object({
  duplicateSession: z.boolean(),
  newSessionUrl: z.string(),
})

export type LeapfundState = z.infer<typeof leapfundStateSchema>

export enum FlagChangeSource {
  MANUAL,
  AUTOMATIC,
}

export interface LeapfundDataBK {
  expenses: ExpensesBK
  incomes: IncomesBK
  bkResult: BkResult
  bingData: BingData
  resources: ResourcesBK
  calculated: CalculatedBK
  params: LeapfundDataParamsBK
  persons: { age: number }[]
}

export const userAndOrgDataSchema = z.object({
  porta_user_id: z.string(),
  porta_user_role: z.string(),
  porta_organization_id: z.string(),
  porta_organization_name: z.string(),
  client_id: z.string(),
})
export type UserAndOrgData = z.infer<typeof userAndOrgDataSchema>

export interface UserAndOrgDataLegacy {
  porta_user_id: string
  porta_organization_id: string
  porta_organization_name: string
}

export interface BingData {
  responseMeta: ResponseMeta
  request: BingDataRequest
  response?: any
}

export interface BingDataRequest {
  url: string
  params: PurpleParams
}

export interface PurpleParams {
  address: string
  persons: ParamsPerson[]
}

export interface ParamsPerson {
  age: string
  loop_iteration_id: string
  loop_iteration_index: string
  loop_edit_iteration_id: string
}

export interface ResponseMeta {
  message: string
  status: number
}

export interface BkResult {
  response: Response
  request: BkResultRequest
}

export interface BkResultRequest {
  params: LeapfundDataParamsBK
  url: string
}

export type IncomeTimePeriod = 'hour' | 'week' | 'month' | 'year'

export interface LeapfundDataParamsBK {
  adult_health_premium: number
  categories: string
  child_care_cost: number
  child_care_hours: number
  child_health_premium: number
  child_support_expense: number
  child_support_income: number
  client_id: string
  employer_subsidized_healthcare: boolean
  feed_family_cost: number
  filing_jointly: boolean
  food_stamps: number
  homeless: boolean
  hours_per_week: number
  housing_costs: number
  income: number
  income_time_period: IncomeTimePeriod
  is_client_id_provided: boolean
  lifeline: boolean
  live_zip: string
  living_apart: boolean
  main_house_child: boolean
  married: boolean
  medicaid: boolean
  monthly_income: number
  other_income: number
  persons: number[]
  pregnant: boolean
  receives_food_stamps: boolean
  receives_welfare: boolean
  receives_wic: boolean
  session_id: string

  ssdi_amount: number
  ssdi_blind: boolean
  ssi_65plus: boolean
  ssi_amount: number
  ssi_children_number: number
  ssi_coupleorindividual?: 'couple' | 'individual'
  ssi_kids_amount: number
  ssi_other_amount: number
  ssi_spouse_amount: number
  ssi_you_amount: number

  trigger: string
  unemployment_amount: number
  utilities_in_name: boolean
  view: string
  welfare_amount: number
  wic: number
  work_study_amount: number
}

export interface Response {
  data: Data
  status: number
}

export interface Data {
  calculators: Calculators
  user_info: UserInfo
}

export type Calculators = BKApi.Calculators

export interface Cares {
  cares_eligibility: string
  cares_benefit_name: string
  cares_amount: number
}

export interface Cctc {
  cctc_eligibility: string
  cctc_benefit_name: string
  cctc_amount: number
  federal_cctc_amount: number
  state_cctc_amount: number
  city_cctc_amount: number
}

export interface Childcare {
  childcare_eligibility: string
  childcare_benefit_name: string
  childcare_copay: number
  childcare_original_cost: number
  childcare_savings: number
}

export interface Ctc {
  ctc_eligibility: string
  ctc_benefit_name: string
  ctc_refundable_portion: number
  ctc_nonrefundable_portion: number
  ctc_total: number
}

export interface Eitc {
  eitc_eligibility: string
  eitc_benefit_name: string
  eitc_amount: number
  fed_eitc_amount: number
  state_eitc_amount: number
  yctc_amount: number
}

export interface Foodstamps {
  foodstamp_eligibility: string
  foodstamp_benefit_name: string
  foodstamp_amount: number
}

export interface Healthcare {
  adult_health_eligibility: string
  adult_health_name: string
  adult_health_copay: number
  child_health_eligibility: string
  child_health_name: string
  child_health_copay: number
}

export interface HighSpeedInternet {
  internet_eligibility: string
  internet_benefit_name: string
  internet_copay: number
}

export interface Ssissd {
  ssissd_eligibility: string
  ssissd_benefit_name: string
  ssissd_amount: number
  ssissd_message: string
}

export interface Taxes {
  annual_income: number
  tax_note: string
  cumulative_tax_name: string
  cumulative_tax_amount: number
  cumulative_tax_rate: number
  federal_tax_name: string
  federal_tax_amount: number
  federal_tax_rate: number
  state_tax_name: string
  state_tax_amount: number
  state_tax_rate: number
}

export interface Unemployment {
  unemployment_eligibility: string
  unemployment_benefit_name: string
  unemployment_amount: number
  unemployment_note: string
}

export interface Utilities {
  heap_eligibility: string
  heap_benefit_name: string
  heap_amount: number
  lifeline_eligibility: string
  lifeline_benefit_name: string
  lifeline_amount: number
}

export interface Welfare {
  welfare_eligibility: string
  welfare_benefit_name: string
  welfare_amount: number
  welfare_county: string
}

export interface Wic {
  wic_eligibility: string
  wic_benefit_name: string
  wic_amount: number
  headstart_eligibility: string
  schoolmeals_eligibility: string
}

export interface UserInfo {
  zip: string
  persons: UserInfoPerson[]
  family_size: number
  fpl: number
  kinship_family: boolean
  county: string
  state: string
  monthly_income: string
  annual_income: number
}

export interface UserInfoPerson {
  age: string
}

export interface CalculatedBK {
  net_income: number
  foodstamp_amount: number
  welfare_amount: number
  wic_amount: number
}

export interface ExpensesBK {
  childcare: number
  taxes: number
  healthcare: number
  housing_costs: number
  feed_family_cost: number
  child_support_expense: number
}

export interface ResourcesBK {
  input: Input
  links: Link[]
}

export interface Input {
  zip: string
}

export interface Link {
  text: string
  url: string
}

export interface IncomesBK {
  ssdi_amount: number
  ssi_spouse_amount: number
  /**
   * @deprecated i _dont_ think this is used now
   */
  ssi_amount: number
  ssi_you_amount: number
  ssi_kids_amount: number
  ssi_other_amount: number
  foodstamp_amount: number
  welfare_amount: number
  wic_amount: number
  other_income: number
  unemployment_amount: number
  work_study_amount: number
  child_support_income: number
  /** this is normalized to be monthly */
  main_income: number
  net_income: number
}

export type IncludeFlags = {
  include_wic: boolean
  include_snap: boolean
  /** TODO: rename to include_tanf */
  include_welfare: boolean
  include_child_care_voucher: boolean
}
