export function formatCurrencyPrecise(value: number) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value)
}

export function formatCurrencySmart(value: number) {
  if (Number.isInteger(value)) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value)
  } else {
    return formatCurrencyPrecise(value)
  }
}
