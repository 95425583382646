import ahoy from 'ahoy.js'
import React from 'react'

export const GuidebookLink = ({
  href,
  event: eventText,
}: {
  href: string
  event?: string
}) => {
  return (
    <>
      [
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          if (eventText) {
            ahoy.track(eventText, { language: 'JavaScript' })
          }
        }}
      >
        Guidebook
      </a>
      ]
    </>
  )
}
