import React from 'react'

export function Details({ data }: { data: any }) {
  if (!data.bkResult)
    return (
      <div>
        missing data
        <details>
          <summary>debug</summary>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </details>
      </div>
    )

  return (
    <div className="details">
      <br />
      <br />

      <details>
        <summary>URL Parameters</summary>
        <QueryStringDebug />
      </details>
      <details>
        <summary>Benefit Kitchen API Response Data</summary>
        <pre>{JSON.stringify(data.bkResult, null, 2)}</pre>
      </details>
      <details>
        <summary>Bing API Response Data</summary>
        <pre>{JSON.stringify(data.bingData, null, 2)}</pre>
      </details>
    </div>
  )
}

const QueryStringDebug = () => {
  let url = new URL(window.location.href)
  let rows: Array<[string, string]> = []

  for (let [x, y] of url.searchParams.entries()) {
    rows.push([x, y])
  }

  rows.sort((a, b) => {
    return a[0].localeCompare(b[0])
  })

  return (
    <form method="get">
      <table className="table table-sm">
        <tbody>
          {rows.map(([x, y], i) => (
            <tr key={i}>
              <td>{x}</td>
              <td>
                <input defaultValue={y} name={x} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button type="submit" className="btn btn-primary">
        Update
      </button>
    </form>
  )
}
